<template>
    <div>
        <el-row :gutter="30" style="margin-left: 0px;margin-right: 0px;">
            <el-col class="center-el-col solution-infos">
                <el-col :lg="8" :sm="12" :xs="20" class="solution-card">
                    <img src="../../img/solution/yiliao_hangye.png">
                    <el-col :lg="19" class="card-info">
                        <el-col class="card-title">医疗行业</el-col>
                        <el-col class="card-content">帮助医疗企业打造统一的ISO文件标准管理平台，方便各科室的文件协作及权限管控，降低资料外泄、误删、损坏等风险，实现文档管理的规范化和标准化。</el-col>
                        <el-col class="solution-show">
                            <el-col class="btn-text" @click.native="goSolution('medicalSolution')">
                                <el-col :span="20" class="btn-title">查看解决方案</el-col>
                                <el-col :span="1" class="btn-arrow">
                                    <img src="../../img/solution/arrow.png">
                                </el-col>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-col>
                <el-col :lg="8" :sm="12" :xs="20" class="solution-card">
                    <img src="../../img/solution/zhizao_hangye.png">
                    <el-col class="card-info">
                        <el-col class="card-title">制造行业</el-col>
                        <el-col class="card-content">帮助制造行业从设计、发行、生产、归档等各环节中图纸文件的使用和管理，一步解决企业文档管理难题，打造无纸化办公的规范化和标准化升级。</el-col>
                        <el-col class="solution-show">
                            <el-col class="btn-text" @click.native="goSolution('makingSolution')">
                                <el-col :span="20" class="btn-title">查看解决方案</el-col>
                                <el-col :span="1" class="btn-arrow">
                                    <img src="../../img/solution/arrow.png">
                                </el-col>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-col>
                <el-col :lg="8" :sm="12" :xs="20" class="solution-card">
                    <img src="../../img/solution/jinrong_hangye.png">
                    <el-col class="card-info">
                        <el-col class="card-title">金融行业</el-col>
                        <el-col class="card-content">帮助金融企业实现文件电子化管理、规范文件管理流程，保障重要文件的及时传递与准确的版本控制，提高单位员工的整体协作效率。</el-col>
                        <el-col class="solution-show">
                            <el-col class="btn-text" @click.native="goSolution('financeSolution')">
                                <el-col :span="20" class="btn-title">查看解决方案</el-col>
                                <el-col :span="1" class="btn-arrow">
                                    <img src="../../img/solution/arrow.png">
                                </el-col>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-col>
                <el-col :lg="8" :sm="12" :xs="20" class="solution-card">
                    <img src="../../img/solution/jianzhu_hangye.png">
                    <el-col class="card-info">
                        <el-col class="card-title">房产建筑行业</el-col>
                        <el-col class="card-content">帮助行业优化建筑项目图纸从现场施工到工程验收整个过程中的文件协作和管控难题，打造统一的图文档管理平台。</el-col>
                        <el-col class="solution-show">
                            <el-col class="btn-text" @click.native.native="goSolution('buildingSolution')">
                                <el-col :span="20" class="btn-title">查看解决方案</el-col>
                                <el-col :span="1" class="btn-arrow">
                                    <img src="../../img/solution/arrow.png">
                                </el-col>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-col>
                <el-col :lg="8" :sm="12" :xs="20" class="solution-card">
                    <img src="../../img/solution/shiwusuo.png">
                    <el-col class="card-info">
                        <el-col class="card-title">事务所</el-col>
                        <el-col class="card-content">帮助事务所行业实现项目电子化办公，达到项目协作智能化，文件传输安全化，大大地提升律所行业办公的效率，以及信息传播和收集的严谨性。</el-col>
                        <el-col class="solution-show">
                            <el-col class="btn-text" @click.native="goSolution('officeSolution')">
                                <el-col :span="20" class="btn-title">查看解决方案</el-col>
                                <el-col :span="1" class="btn-arrow">
                                    <img src="../../img/solution/arrow.png">
                                </el-col>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-col>
                <el-col :lg="8" :sm="12" :xs="20" class="solution-card">
                    <img src="../../img/solution/wuliu_hangye.png">
                    <el-col class="card-info">
                        <el-col class="card-title">物流行业</el-col>
                        <el-col class="card-content">帮助传统物流企业在数据采集、仓储管理、物流运输、客户管理等方面实现无纸质化办公，加强内部数据资产的管控，保障信息安全，打造统一的文档平台。</el-col>
                        <el-col class="solution-show">
                            <el-col class="btn-text" @click.native="goSolution('logisticsSolution')">
                                <el-col :span="20" class="btn-title">查看解决方案</el-col>
                                <el-col :span="1" class="btn-arrow">
                                    <img src="../../img/solution/arrow.png">
                                </el-col>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-col>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    name: "SolutionIndex",
    data(){
        return {
            homeCarousel: [
                require("assets/img/home.png"),
                require("assets/img/teach_home.png"),
            ],
            officeEnv:require("assets/img/officeEnv/002.png")
        }
    },
    methods: {
        goSolution(path) {
            let router = "/" + path;
            this.$router.push({path: router});
        }
    }
}


</script>

<style lang="less" scoped>
.center-el-col {
    margin: auto;
    float: unset;
}
.hotSolution {
    background: url("../../img/solution/solution_bg.png") no-repeat top center;
    height: 450px;
}

.solution-infos {
    width: 100%;
    margin: 1rem auto;
    max-width: 1200px;
  padding: 0 !important;
}

.solution-card {
    padding: 0 !important;
    margin-bottom: 10rem;
    position: relative;
    text-align: center;
}

.card-info {
    width: 336px;
    height: 225px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 5px;
    box-shadow: 0px 4px 16px rgba(52,52,78,0.16);
    position: absolute;
    top: 10rem;
    left: 1.5rem;
}

.card-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 80px;
    height: 80px;
    text-align: center;
    color: #3764D7;
}

.card-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #333333;
}

.solution-show {
    background: #2974E4;
    box-shadow: 0px 4px 16px rgba(52,52,78,0.16);
    border-radius: 2px;
    width: 169px;
    height: 43px;
    line-height: 43px;
    padding-left: 1.5rem;
    margin-right: 1rem;
    display: flex;
    flex-wrap: wrap;
    color: white;
    cursor: pointer;
    position: absolute;
    bottom: -1.2rem;
    right: -2rem;
}

.btn-title {
    font-size: 16px;
}

.btn-arrow {
    font-size: 16px;
}

.btn-title:hover{
    color: #99a9bf;
}

.solution-show img {
    width: 20px;
    height: 20px;
}

.solution-show div {
    padding: 0px !important;
}

.btn-text {
    text-align: center;
}

.btn-arrow {
    margin-top: 3.5px;
}

@media screen and (max-width: 1200px){
    .card-info {
        left: 2rem;
    }

    .solution-infos {
        max-width: 820px;
    }
}

@media screen and (max-width: 992px){
    .card-info {
        left: 1.5rem;
    }
}

@media screen and (max-width: 767px){
    .card-info {
        left: 2rem;
    }

    .solution-infos {
        max-width: 400px;
    }
}

@media only screen and (max-width: 767px) {
  .el-col-xs-20 {
    width: 100%;
  }
}


</style>