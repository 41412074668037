// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/solution/solution_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".center-el-col[data-v-58fedc78]{margin:auto;float:unset}.hotSolution[data-v-58fedc78]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;height:450px}.solution-infos[data-v-58fedc78]{width:100%;margin:1rem auto;max-width:1200px;padding:0!important}.solution-card[data-v-58fedc78]{padding:0!important;margin-bottom:10rem;position:relative;text-align:center}.card-info[data-v-58fedc78]{width:336px;height:225px;background:#fff;opacity:1;border-radius:5px;box-shadow:0 4px 16px rgba(52,52,78,.16);position:absolute;top:10rem;left:1.5rem}.card-title[data-v-58fedc78]{font-size:24px;font-weight:700;line-height:80px;height:80px;text-align:center;color:#3764d7}.card-content[data-v-58fedc78]{font-size:16px;font-weight:400;line-height:26px;color:#333}.solution-show[data-v-58fedc78]{background:#2974e4;box-shadow:0 4px 16px rgba(52,52,78,.16);border-radius:2px;width:169px;height:43px;line-height:43px;padding-left:1.5rem;margin-right:1rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;color:#fff;cursor:pointer;position:absolute;bottom:-1.2rem;right:-2rem}.btn-arrow[data-v-58fedc78],.btn-title[data-v-58fedc78]{font-size:16px}.btn-title[data-v-58fedc78]:hover{color:#99a9bf}.solution-show img[data-v-58fedc78]{width:20px;height:20px}.solution-show div[data-v-58fedc78]{padding:0!important}.btn-text[data-v-58fedc78]{text-align:center}.btn-arrow[data-v-58fedc78]{margin-top:3.5px}@media screen and (max-width:1200px){.card-info[data-v-58fedc78]{left:2rem}.solution-infos[data-v-58fedc78]{max-width:820px}}@media screen and (max-width:992px){.card-info[data-v-58fedc78]{left:1.5rem}}@media screen and (max-width:767px){.card-info[data-v-58fedc78]{left:2rem}.solution-infos[data-v-58fedc78]{max-width:400px}}@media only screen and (max-width:767px){.el-col-xs-20[data-v-58fedc78]{width:100%}}", ""]);
// Exports
module.exports = exports;
